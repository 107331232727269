// =============================================================================
//
//  Login Page Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// General
$login-full__width: 100%;
$login-page-heading__margin: ($base-margin * 2.5) 0;
$login-page-heading__color: $black-two;
$login-page-heading__margin--large: ($base-margin * 3.9) 0 ($base-margin * 4.5);
$login-button__width: 100%;
$login-button__max-height--large: 6rem;
$login-button__line-height--large: 1;
$login-button__padding: ($base-padding * 2) ($base-padding * 4.5);
$login-button-wrapper__margin-bottom: $base-margin * 4;
$login-button-wrapper__margin-top--large: $base-margin * 0.7;
$login-create-account-button-wrapper__margin-top--large: $base-margin * 3.5;
$login-tabs-border__padding-left: $base-padding * 1.2;
$login-tabs-border__height: $border;
$login-tabs-before__background-color: #e5e5e5;
$login-tabs-item-active__height: 0.6rem;
$login-tabs-items__padding: 0 ($base-padding * 1.2);
$login-tabs-header__margin: 1rem -50vw 3rem;
$login-form-checkbox-title__padding-left: $base-padding * 3.3;
$login-forgot-password-link__color: $greyish-brown-two;
$login-forgot-password-link__text-decoration-color: #979797;
$login-form-input__padding: $base-padding ($base-padding * 1.8);
$login-form-input__padding--large: ($base-padding * 1.65) ($base-padding * 1.8);
$login-form-input-label__padding-bottom: $base-padding * 0.5;
$login-form-input-label__color: $brown-grey-two;
$login-form-bottom__margin-top: $base-margin * 2.5;
$login-form-bottom__margin-top--large: $base-margin * 3;
$login-track-order-header-text__margin: $base-margin 0;
$login-track-order-header-text__color: $greyish-brown-two;
$login-cancel-password-button__color: $greyish-brown-two;
$login-alert__margin-bottom: $base-margin * 1.5;
$login-alert_color: $red;

// Login Page
$login-sections-left-right__flex--large: 0 0 50%;
$login-sections-left-right-side__padding--large: $base-padding * 11.5;
$login-sections-left__border-right--large: $border solid rgba($black, 0.2);
$login-page-tab-item__padding--large: 0 ($base-padding * 2);
$login-page-tabs-content__margin-top--large: $base-margin * 4.7;
$login-wrapper__padding-bottom--large: $base-padding * 10;

// Track Your Order Page
$login-track-your-order-heading__margin: ($base-margin * 3) 0 0;
$login-track-your-order-heading__padding-bottom: $base-padding;
$login-track-your-order-heading-separator__width: 100%;
$login-track-your-order-heading-separator__height: 0.1rem;
$login-track-your-order-heading-separator__background-color: $brown-grey;
$login-track-your-order-text__margin--large: ($base-margin * 0.5) 0 ($base-margin * 3.3);
$login-track-order-button__margin-top--large: $base-margin * 2.8;
$login-track-order-heading__color: $black-two;

// Forgot Password
$login-send-email-button-wrapper__margin-bottom: $base-margin * 2.3;
$login-send-email-button-wrapper__margin-bottom--large: $base-margin * 3.3;
$login-cancel-password-button-wrapper__margin-bottom: $base-margin * 10;
$login-requested-password-text__width: 90%;
$login-requested-password-text__color: $greyish-brown-two;
$login-requested-password-text__margin-bottom: $base-margin * 2.5;
$login-profile-edit-buttons__margin-bottom: $base-margin * 2;
$login-request-new-password__margin: ($base-margin * 3) 0;
$login-request-new-password__margin--large: ($base-margin * 5) auto;
$login-request-new-password__width--large: 50%;

// Login Asset
$login-bottom__margin: ($base-margin * 6) 0 0;
$login-bottom-content__background-color: $black;
$login-bottom-content-liner-gradient__background-image: -184deg, $red 29.7%, $wine-red 30%;
$login-bottom-content__padding: ($base-padding * 3) ($base-padding * 2.4) ($base-padding * 3);
$login-bottom-content-header-title__margin-bottom: $base-margin * 2;
$login-bottom-content-body__margin: ($base-margin * 7) 0 0;
$login-bottom-content-body-text__margin-bottom: $base-margin * 1.7;
$login-bottom-content-list-item__margin-bottom: $base-margin;
$login-bottom-list-items__color: $white;
$login-bottom-content-header-title__width: 50%;
$login-bottom-content-header-title__font-weight: 400;
$login-bottom-content-header-title-bold__font-weight: $bolder;
$login-icon-circle__width: 0.8rem;
$login-icon-circle__height: 0.8rem;
$login-icon-circle__background-color: $white;
$login-icon-circle__margin-right: $base-margin * 1.3;
$login-asset-button__margin-top: $base-margin * 3;
$login-asset-button__width: 20rem;

$login-bottom-content-body__width--medium: 100%;

$login-bottom-content__padding--large: ($base-padding * 3) ($base-padding * 2.4) ($base-padding * 3);
$login-bottom-content-body__margin--large: ($base-margin * 7) 0 0;
$login-bottom-content-liner-gradient__background-image--large: -184deg, $red 35.7%, $wine-red 36%;

// IE compatibility
$ie-login-section-left-right__flex--large: 0 0 35%;
