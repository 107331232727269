// =============================================================================
//
//  Tabs Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$tabs-header__border-bottom: rgba(218, 218, 218, 0.5) solid $border;
$tabs-header__margin: ($base-margin) -50vw;

$tabs-header-item__padding: 0 ($base-padding * 2);
$tabs-header-item__color: $brown-grey;

$tabs-header-item-active__color: $black-two;
$tabs-header-item-active__font-weight: $bolder;

$tabs-header-item-active-rectangle__height: 0.4rem;
$tabs-header-item-active-rectangle__margin: $base-margin;
$tabs-header-item-active-rectangle__background-color: $orange;

// =============================================================================
//  Medium Screen
// =============================================================================

$tabs-header-item__padding--medium: 0 ($base-padding * 6);
