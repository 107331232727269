// =============================================================================
//
//  Login Page Style
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../themes/default/components/tabs';
@import '../themes/default/login/login';

.login {
    &-wrapper {
        .tabs-header {
            border: none;
            margin: $login-tabs-header__margin;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: calc(#{$login-full__width} - #{$login-tabs-border__padding-left});
                height: $login-tabs-border__height;
                background-color: $login-tabs-before__background-color;
            }

            > .item {
                @include get-font('small');
                padding: $login-tabs-items__padding;
            }
        }

        .item.active > .rectangle {
            height: $login-tabs-item-active__height;
        }

        .tabs-content > .item.active {
            display: block !important;
        }

        .form-group {
            .form-control-label {
                @include get-font('xsmall');
                display: block;
                padding-bottom: $login-form-input-label__padding-bottom;
                color: $login-form-input-label__color;
            }
        }

        .trackorder {
            .alert-danger {
                @include get-font('xsmall');
                color: $red;
            }
        }

        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='url'],
        input[type='color'],
        input[type='date'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='month'],
        input[type='time'],
        input[type='week'],
        textarea,
        select {
            padding: $login-form-input__padding;
        }
    }

    &-heading {
        margin: $login-page-heading__margin;
        color: $login-page-heading__color;
    }

    &-button {
        &:disabled,
        [disabled='disabled'] {
            cursor: default;
        }
    }

    &-button,
    &-create-account-button,
    &-send-email-button {
        @include get-font('medium');
        width: $login-button__width;
    }

    &-button-wrapper,
    &-create-account-button-wrapper {
        margin-bottom: $login-button-wrapper__margin-bottom;

    }

    &-form-bottom {
        @include flexbox();
        @include justify-content(flex-end);
        margin-top: $login-form-bottom__margin-top;
        margin-bottom: 1rem;

        .form-control-checkbox-title {
            @include get-font('xsmall');
            padding-left: $login-form-checkbox-title__padding-left;
        }
    }

    &-forgot-password-link {
        @include get-font('xsmall');
        color: $login-forgot-password-link__color;
        text-decoration: underline;
        text-decoration-color: $login-forgot-password-link__text-decoration-color;
    }

    &-track-order-button {
        width: $login-button__width;
    }

    &-track-order-heading {
        position: relative;
        padding-bottom: $login-track-your-order-heading__padding-bottom;
        margin: $login-track-your-order-heading__margin;
        color: $login-track-order-heading__color;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: $login-track-your-order-heading-separator__width;
            height: $login-track-your-order-heading-separator__height;
            background-color: $login-track-your-order-heading-separator__background-color;
        }
    }

    &-track-order-header-text {
        @include get-font('xsmall');
        margin: $login-track-order-header-text__margin;
        color: $login-track-order-header-text__color;
    }

    &-requested-password-text {
        width: $login-requested-password-text__width;
        margin-bottom: $login-requested-password-text__margin-bottom;
        color: $login-requested-password-text__color;
    }

    &-reset-password-body {
        margin-bottom: $login-requested-password-text__margin-bottom;
    }

    &-send-email-button-wrapper {
        margin-bottom: $login-send-email-button-wrapper__margin-bottom;
    }

    &-cancel-password-button {
        @include get-font('xsmall');
        color: $login-cancel-password-button__color;
    }

    &-cancel-password-button-wrapper {
        margin-bottom: $login-cancel-password-button-wrapper__margin-bottom;
    }

    &-terms-and-conditions {
        @include get-font('small');

        .login-terms-policy-link {
            @include get-font('small');
        }
    }

    &-save-button,
    &-cancel-button {
        margin-bottom: $login-profile-edit-buttons__margin-bottom;
    }

    &-save-btn {
        width: $login-full__width;
    }

    &-request-new-password {
        margin: $login-request-new-password__margin;
    }

    &-bottom {
        margin: $login-bottom__margin;
    }

    &-bottom-content {
        width: $login-full__width;
        padding: $login-bottom-content__padding;
        background-color: $login-bottom-content__background-color;
    }

    &-bottom-content-header-title {
        width: $login-bottom-content-header-title__width;
        margin-bottom: $login-bottom-content-header-title__margin-bottom;
        color: $white;
        font-weight: $login-bottom-content-header-title__font-weight;
    }

    &-asset-title-bold {
        font-weight: $login-bottom-content-header-title-bold__font-weight;
    }

    &-bottom-content-list {
        padding: 0;
        margin: 0;
        color: $login-bottom-list-items__color;
        list-style: none;
    }

    &-bottom-content-body {
        margin: $login-bottom-content-body__margin;
    }

    &-bottom-content-body-text,
    &-bottom-content-list-item {
        @include get-font('compact');
    }

    &-bottom-content-body-text {
        margin-bottom: $login-bottom-content-body-text__margin-bottom;
    }

    &-bottom-content-list-item {
        @include flexbox();
        @include align-items(center);
        margin-bottom: $login-bottom-content-list-item__margin-bottom;

        &:last-child {
            margin-bottom: 0;
        }

        .icon-circle {
            width: $login-icon-circle__width;
            height: $login-icon-circle__height;
            border-radius: 100%;
            margin-right: $login-icon-circle__margin-right;
            background-color: $login-icon-circle__background-color;
        }
    }

    &-asset-button {
        @include get-font('medium');
        display: inline-block;
        width: $login-asset-button__width;
        margin-top: $login-asset-button__margin-top;
        text-align: center;
        text-decoration: none;

        &:hover {
            background: $orange-dash !important;
            color: $white;
        }
    }

    .alert {
        @include get-font('small');
        margin-bottom: $login-alert__margin-bottom;
        color: $login-alert_color;
    }

}

@import '../screens/medium/login/login';
@import '../screens/large/login/login';
